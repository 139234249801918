<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />
            <h1 class="title special" data-inview="fadeInUp" data-delay="200">{{ $t('Mellön présente') }}</h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300">
                <span v-html="contentIsLoaded ? content.titre : '&nbsp;'"></span>
            </h2>
        </div>

        <div class="beer-details" v-if="contentIsLoaded" hero-transition-group>
            <div class="img" data-inview="revealRight" data-delay="400">
                <img :src="content.imageSimple.evenement.url" :alt="content.imageSimple.evenement.titre" />
            </div>
            <div class="data" data-inview="slideLeft" data-delay="500">
                <div class="row full">
                    <h3 class="title">{{ parseDate(content.dateDeLevenement.date) }}</h3>
                </div>
                <div class="row full">
                    <p class="text">{{ content.debut }}</p>
                    <p class="text vert space">À</p>
                    <p class="text">{{ content.fin }}</p>
                </div>
                <div class="content short">
                    <div class="c-row" v-for="(item, i) in content.details" :key="i">
                        <div class="left">
                            <p class="text">{{ item.titre }}</p>
                        </div>
                        <div class="right">
                            <p class="text"><span v-html="item.texte"></span></p>
                        </div>
                    </div>
                </div>
                <a href="#contact" v-scroll-to:-0 class="row full link">
                    <h3 class="title">{{ $t('Partants? Visitez-nous!') }}</h3>
                    <img src="@/assets/img/arrow-down.svg" alt="Arrow down" />
                </a>
            </div>
        </div>

        <div class="main-title-wrap" v-if="pageIsLoaded">
            <h1 class="title special" data-inview="fadeInUp" data-delay="100">{{ $t('Autres Événements') }}</h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="100"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="200">{{ $t('Ça aussi ça s\'en vient!') }}</h2>
        </div>

        <section class="featured-events" v-if="contentIsLoaded">
            <router-link
                :to="item.page.jsonUrl"
                class="event-wrap"
                data-inview="fadeInUp"
                data-delay="100"
                v-for="(item) in evenementsEnVedette" :key="item.page.id"
            >
                <div class="img">
                    <img :src="item.page.imageSimple.evenement.url" class="main-img-beer" :alt="item.page.imageSimple.evenement.titre" />
                </div>
                <div class="infos">
                    <div class="name">
                        <h4 class="title">{{ item.page.titre }}</h4>
                    </div>
                    <div class="date">
                        <p class="text">{{ parseDate(item.page.dateDeLevenement.date) }}</p>
                        <div class="arrow">
                            <svg
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                <path
                                    d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                    fill="#2E3E30"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </router-link>

            <div class="cta-wrap">
                <router-link :to="{ name: 'Evenements-' + $route.meta.lang }" class="cta" data-inview="scale" data-delay="100">
                    <p class="text">{{ $t('Voir tous les événements') }}</p>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'EvenementsEntry',

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        evenementsEnVedette() {
            if (!this.pageIsLoaded) {
                return []
            }

            const results = this.globals.evenementEntry.data.filter(i => i.page.id !== this.content.id)

            return results.slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        parseDate(date) {
            moment.locale(this.$route.meta.lang)
            return moment(date).format('DD MMMM YYYY')
        }
    },
}
</script>
